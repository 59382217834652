
#services {
  background: url('../../assets/about-bg.jpg') center top no-repeat fixed;
  background-size: cover;
  padding: 60px 0 40px 0;
  position: relative;
}

#services::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.92);
  z-index: 9;
}

#services .container {
  position: relative;
  z-index: 10;
}

#services .services-col {
  background: #fff;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
}

#services .services-col .img {
  position: relative;
}

#services .services-col .img img {
  border-radius: 4px 4px 0 0;
}

#services .services-col .icon {
  width: 64px;
  height: 64px;
  text-align: center;
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  border: 4px solid #fff;
  left: calc(50% - 32px);
  bottom: -30px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}


.iccon {
    color: #000000; /* Set the default color */
    transition: color 0.3s ease; /* Add a smooth transition */
  }
  
  
  
  #services .services-col:hover .icon .iccon {
    color: #353333;
  }

#services .services-col:hover .icon {
  background-color: #ffffff;
}



#services .services-col h2 {
  color: #000000;
  text-align: center;
  font-weight: 500;
  font-size: 30px;
  padding: 0;
  margin: 40px 0 12px 0;
  font-weight: bold;

}

#services .services-col h2 a {
  color: #000000;
  text-decoration: none;
}

#services .services-col h2 a:hover {
  color: #353333;
}

#services .services-col p {
  font-size: 15px;
  line-height: 24px;
  color: #252525;
  margin-bottom: 0;
  margin-top: 30px;
  padding: 0 20px 20px 20px;
  font-style: italic;
}


.section-header h3 {
    font-size: 32px;
    color: #111;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    position: relative;
    padding-bottom: 15px;
  }
  
  .section-header h3::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }
  
  .section-header h3::after {
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #f7f57f;
    bottom: 0;
    left: calc(50% - 20px);
  }
  
  .section-header p {
    text-align :center;
    padding-bottom: 30px;
    color: #333;
    font-size: 18px;
  }