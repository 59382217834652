
#overview{
    font-size: 20px;
    justify-content: center;
    
    
}
/* #over{
    background-color: rgb(255, 255, 255);
    
} */
.section-titre {
    text-align: center;
    
  }
  
  .section-titre h2 {
    font-size: 32px;
    font-weight:bolder;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #000000;
    font-family: "Open Sans", sans-serif;
  }
  
  #over_desc {
    margin-bottom: 0;
    font-size: 20px;
    justify-content: center;
    margin: 50px;
    
  }


 /* LogosList.css */
.logos-container {
    display: flex;
    align-items: center;
  }
  
  .logo-img {
    width: 100px;
    height: auto;
    margin: 30px;

  }
  .logo-img:hover{
    opacity: 0.3;
  }
  
  /**************************/

  /* .container_results {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

.container_results  h1 {
    text-align: center;
    margin-bottom: 20px;
}

.container_results  p {
    line-height: 1.6;
    text-align: justify;
}

.source {
    font-size: 12px;
    color: #888;
    text-align: center;
    margin-top: 20px;
} */
#discount{
  font-size: 70px;
  color: rgb(0, 0, 100);
  font-weight: bold;
}
/* .results{
  background-color: rgb(213, 213, 255);
} */