.datascience {
    width: 100%;
    height: 100vh;
    background: url('../../assets/scienceback.png') top center no-repeat;
    background-size: cover;
  }
  
  .datascience h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 300;
    color: #272424;
  }
  .datascience span{
    font-weight: bold;
    color :#111
  }
  
  .datascience h2 {
    color: #444444;
    margin: 15px 0 0 0;
    font-size: 22px;
  }
  
  /* .datascience .btn-get-started {
    margin-top: 30px;
    line-height: 0;
    padding: 15px 40px;
    border-radius: 4px;
    transition: 0.5s;
    color: #fff;
    background: #4154f1;
    box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
  }
  
  .datascience .btn-get-started span {
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
  }
  
  .datascience .btn-get-started i {
    margin-left: 5px;
    font-size: 18px;
    transition: 0.3s;
  }
  
  .datascience .btn-get-started:hover i {
    transform: translateX(5px);
  } */
  
  .datascience .datascience-img {
    text-align: right;
  }
  
  @media (min-width: 1024px) {
    .datascience {
      background-attachment: fixed;
    }
  }
  
  @media (max-width: 991px) {
    .datascience {
      height: auto;
      padding: 120px 0 60px 0;
    }
  
    .datascience .datascience-img {
      text-align: center;
      margin-top: 80px;
    }
  
    .datascience .datascience-img img {
      width: 80%;
    }
  }
  
  @media (max-width: 768px) {
    .datascience {
      text-align: center;
    }
  
    .datascience h1 {
      font-size: 32px;
    }
  
    .datascience h2 {
      font-size: 24px;
    }
  
    .datascience .datascience-img img {
      width: 100%;
    }
  }


  #datascience .btn-get-started {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 28px;
    border-radius: 3px;
    transition: 0.5s;
    color: #000000;
    background: #f1ef71;
    text-decoration: none;
    margin-top: 20px;
}

#datascience .btn-get-started:hover {
    background: #000000;
    color:bisque;
}

.container_science{
    margin: 100px;
    margin-top: -50px;
  
    
}



.datasc{
    background: url('../../assets/scienceback.png') center top no-repeat fixed;
    background-size: cover;
    padding: 60px 0 40px 0;
    position: relative;
  }
  /* .datasc::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.92);
    z-index: 9;
  } */
  .datasc_container{
    position: relative;
  z-index: 10;
  }


/*********************************Services*******************/


/* .sc_services{
    background-color: rgb(247, 244, 241);
} */

.sc_services h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 300;
    color: #272424;
  }
  .sc_services span{
    font-weight: bold;
    color :#111
  }
  .sc_services p {
   
    font-size: 24px;
    font-weight: 300;
    text-align: center;
   
  }

.sc_services .icon-box {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .sc_services .icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 50%;
    transition: 0.5s;
    color: #FCFBBC;
    overflow: hidden;
    box-shadow: 0px 0 25px rgba(0, 0, 0, 0.15);
  }
  
  
  
  .sc_services .icon-box:hover .icon {
    box-shadow: 0px 0 25px rgba(255, 238, 0, 0.3);
  }
  
  .sc_services .title2 {
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 30px;
    position: relative;
    padding-bottom: 15px;
    color: #000000;
    
  }
  
  
  .sc_services .title2  {
    color: #000000;
    transition: 0.3s;
  }
  
  .sc_services .title2 :hover {
    color: #FCFBBC;
  }
  
  .sc_services .title2::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 2px;
    background: #fffb00;
    bottom: 0;
    left: calc(50% - 25px);
  }
  
  .sc_services .description {
    line-height: 24px;
    font-size: 18px;
    font-weight: 500;
  }
  