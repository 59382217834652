.sol2 .sol2-box {
    padding: 24px 20px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
    transition: 0.3s;
    height: 100%;
  }
  
  
  
  .sol2 .sol2-icons {
    margin-top: 120px;
  }
  #khrya{
    margin-top: 60px;
    height: 400px;
  }
  
  .sol2 .sol2-icons h3 {
    color: rgb(214, 211, 10);
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .sol2 .sol2-icons h3 {
      font-size: 28px;
    }
  }
  
  .sol2 .sol2-icons .content .icon-box {
    display: flex;
  }
  
  .sol2 .sol2-icons .content .icon-box h4 {
    font-size: 22px;
    font-weight: 700;
    margin: 0 0 10px 0;
    color: #000000;
  }
  
  .sol2 .sol2-icons .content .icon-box .sol2_icon {
    font-size: 35px;
    line-height: 2px;
    color: rgb(235, 232, 75);
    margin-right: 20px;
  }
  
  .sol2 .sol2-icons .content .icon-box p {
    line-height: 24px;
    font-size: 17px;
    font-weight: 500;
    color: #333333;
  }
  