body {
    font-family: "Open Sans", sans-serif;
    color: #444444;
    background-color: #000000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Raleway", sans-serif;
}

#header {
    height: 72px;
    transition: all 0.5s;
    z-index: 997;
    background: #000000;
}

#header.header-scrolled {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#header .logo h1 {
    font-size: 28px;
    margin: 0;
    line-height: 0;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: "Open Sans", sans-serif;
}

#header .logo h1 a,
#header .logo h1 a:hover {
    color: #fcfbbc;
    text-decoration: none;
}

.navbar {
    padding: 0;
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}

.navbar li {
    position: relative;
}

.navbar a,
.navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    color: #7b909a;
    white-space: nowrap;
    transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
    color: #fcfbbc;
}

.navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #f0eeee;
    box-shadow: 0px 0px 30px rgba(58, 58, 59, 0.25);
    transition: 0.3s;
}

.navbar .dropdown ul li {
    min-width: 200px;
}

.navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 14px;
    color: #003651;
}

.navbar .dropdown ul a i {
    font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
    color: #eeec75;
}

.navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
}

@media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }

    .navbar .dropdown .dropdown:hover>ul {
        left: -100%;
    }
}

.mobile-nav-toggle {
    color: #fcfbbc; /* Set the desired color */
    font-size: 28px;
    cursor: pointer;
    display: none; /* Initially hidden */
    line-height: 0;
    transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
    color: #fcfbbc; /* Set the desired color for the 'X' icon */
}

@media (max-width: 991px) {
    .mobile-nav-toggle {
        display: block; /* Show the toggle button on mobile view */
    }

    .navbar ul {
        display: none; /* Hide the navbar items on mobile view */
    }
}

.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    width: 80%;
    height: 100%;
    background: #000000;
    transition: 0.3s;
    z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}

.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: black;
    overflow-y: auto;
    transition: 0.3s;

}

.navbar-mobile a,
.navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #364146;
}

.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: black;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
    min-width: 200px;
    color: white;
}

.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
}

.navbar-mobile .dropdown>.dropdown-active {
    display: block;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  background: #fcfbbc;
  padding: 8px 30px;
  margin-left: 40px;
  border-radius: 4px;
  color: #000000;
  text-decoration: none;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #000000;
  background: #f7f45e;
}

.overlay {
    position: fixed;
    top: 0;
    left: 80%;
    width: 20%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 998;
}
