#filter-buttons {
  list-style: none;
  margin-bottom: 20px;
}


#filter-buttons li {
  cursor: pointer;
  display: inline-block;
  margin: 10px 5px;
  font-size: 1.5vw;
  font-weight: 400;
  line-height: 1;
  color: #444444;
  transition: all 0.3s;
  padding: 8px 20px;
  border-radius: 50px;
  font-family: "Gill Sans", sans-serif;
}
#filter-buttons li:hover{
  background: #000000;
  color: #fff;
  opacity: 1;
}

#filter-buttons li.active {
  background: #000000;
  color: #fff;
}

@media (max-width: 991px) {
  .filter-buttons{
    width: 40vw;
    
  }
  #filter-buttons li{
    font-size: 2.5vw;
  }

  .active{
    height: 5vh;
  }

  #filter-buttons h3{
    font-size: 3vw;
  }
}