/* Paste your CSS content here */
#hero {
    width: 100%;
    background-image:  url('../../assets/v882-kul-66.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right top;
    margin-top: 12px;
    padding: 60px 0;
    height: 100vh;
    
    
    
}
.containerrr {
    
    margin: 100px;
    
    
    
  }

#hero h1 {
    margin: 0 0 20px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #fcfbbc;
}

#hero h2 {
    color:  #ffffff;
    margin-bottom: 30px;
    font-size: 24px;
}

#hero .btn-get-started {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 28px;
    border-radius: 3px;
    transition: 0.5s;
    color: #000000;
    background: #f1ef71;
    text-decoration: none;
}

#hero .btn-get-started:hover {
    background: #000000;
    color:bisque;
}

#hero .hero-img {
    text-align: center;
}

#hero .hero-img img {
    width: 70%;
}

@media (min-width: 1024px) {
    #hero {
        background-attachment: fixed;
    }
}

@media (max-width: 992px) {
    #hero h1 {
        font-size: 32px;
        line-height: 40px;
    }

    #hero h2 {
        font-size: 20px;
    }
}
