/* Features Section - Home Page
------------------------------*/
.features .features-item {
    color: rgba(var(--default-color-rgb), 0.8);
  }
  
  .features .features-item+.features-item {
    margin-top: 100px;
  }
  
  @media (max-width: 768px) {
    .features .features-item+.features-item {
      margin-top: 40px;
    }
  }
  
  .features .features-item h3 {
    font-weight: 700;
    font-size: 26px;
  }
  
  .features .features-item .btn-get-started {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    padding: 8px 30px 10px 30px;
    border-radius: 4px;
  }
  
  .features .features-item .btn-get-started:hover {
    background-color: rgba(var(--accent-color-rgb), 0.9);
  }
  
  .features .features-item ul {
    list-style: none;
    padding: 0;
  }
  
  .features .features-item ul li {
    padding-bottom: 10px;
    display: flex;
    align-items: flex-start;
  }
  
  .features .features-item ul li:last-child {
    padding-bottom: 0;
  }
  
  .features .features-item ul i {
    font-size: 20px;
    padding-right: 4px;
    color: var(--accent-color);
  }
  
  .features .features-item img {
    border: 6px solid var(--background-color);
    box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
  }
  
  .features .features-item .features-img-bg {
    position: relative;
    min-height: 500px;
  }
  
  @media (max-width: 640px) {
    .features .features-item .features-img-bg {
      min-height: 300px;
    }
  }
  
  .features .features-item .features-img-bg img {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  
  .features .features-item .image-stack {
    display: grid;
    position: relative;
    grid-template-columns: repeat(12, 1fr);
  }
  
  .features .features-item .image-stack .stack-back {
    grid-column: 4/-1;
    grid-row: 1;
    width: 100%;
    z-index: 1;
  }
  
  .features .features-item .image-stack .stack-front {
    grid-row: 1;
    grid-column: 1/span 8;
    margin-top: 20%;
    width: 100%;
    z-index: 2;
  } 
  .features h1 {
    margin: 0;
    font-size: 40px;
    font-weight: 300;
    color: #272424;
  }
  .features span{
    font-weight: bold;
    color :#111
  }

  /*--------------------------------------------------------------
# Call To Action Section
--------------------------------------------------------------*/
.cta {
    padding: 0;
    margin-bottom: 60px;
  }
  
  .cta .container {
    padding: 80px;
    background: rgba(236, 237, 240, 0.9);
    /* background-color: rgb(245, 238, 238); */
    border-radius: 15px;
  }
  
  @media (max-width: 992px) {
    .cta .container {
      padding: 60px;
    }
  }
  
  
  
  .cta .content p {
    /* color: #5670a0; */
    font-weight: 600;
    font-size: 18px;
  }
  
  .cta .content .cta-btn {
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 5px;
    transition: 0.5s;
    margin-top: 10px;
    background: rgba(28, 97, 226, 0.9);
  }
  
  .cta .content .cta-btn:hover {
    background: #3279fc;
  }
  
  .cta .img {
    position: relative;
  }
  
  .cta .img:before {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 15px;
    transform: rotate(12deg);
  }
  
  .cta .img:after {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    transform: rotate(6deg);
  }
  
  .cta .img img {
    position: relative;
    z-index: 3;
    border-radius: 15px;
  }
  .cta h1 {
    margin: 0;
    font-size: 40px;
    font-weight: 300;
    color: #272424;
  } 
  .cta span{
    font-weight: bold;
    color :#111
  }

  /* call-to-action2 Section - Home Page
------------------------------*/
.call-to-action2 {
    --default-color: #ffffff;
    --background-color: #000000;
    --background-color-rgb: 0, 0, 0;
    padding: 80px 0;
    position: relative;
    -webkit-clip-path: inset(0);
    clip-path: inset(0);
  }
  
  .call-to-action2 img {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: 1;
  }
  
  .call-to-action2:before {
    content: "";
    background: rgba(var(--background-color-rgb), 0.1);
    position: absolute;
    inset: 0;
    z-index: 2;
  }
  
  .call-to-action2 .container {
    position: relative;
    z-index: 3;
  }
  
  .call-to-action2 h3 {
    font-size: 28px;
    font-weight: 700;
    color: var(--default-color);
  }
  
  .call-to-action2 p {
    color: var(--default-color);
  }
  
  .call-to-action2 .cta-btn {
    font-family: var(--heading-font);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 5px;
    transition: 0.5s;
    margin: 10px;
    border: 2px solid var(--contrast-color);
    color: var(--contrast-color);
  }
  
  .call-to-action2 .cta-btn:hover {
    background: var(--accent-color);
    border: 2px solid var(--accent-color);
    color: #000000;
  }

  :root {
    --background-color: #ffffff;
    --background-color-rgb: 255, 255, 255;
    --default-color: #212529;
    --default-color-rgb: 33, 37, 41;
    --accent-color: #f7f45e;
    --accent-color-rgb: 232, 69, 69;
    --heading-color: #32353a;
    --heading-color-rgb: 50, 53, 58;
    --contrast-color: #ffffff;
    --contrast-color-rgb: 255, 255, 255;
  }