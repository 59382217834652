
  #about{
    background: url('../../assets/about-bg.jpg') center top no-repeat fixed;
    background-size: cover;
    padding: 60px 0 40px 0;
    position: relative;
  }
  #about::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.92);
    z-index: 9;
  }
  #about_container{
    position: relative;
  z-index: 10;
  }
 
  
  .responsive-container-block {
    min-height: 75px;
    height: fit-content;
    width: 100%;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    justify-content: flex-start;
    
  }
  
  .text-blk {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    line-height: 25px;
  }
  
  .responsive-container-block.bigContainer {
    padding-top: 10px;
    padding-right: 30px;
    padding-bottom: 10px;
    padding-left: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .mainImg {
    color: black;
    width: 30%;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 10px 7px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-left: 70px;
  }
  
  .text-blk.headingText {
    font-size: 40px;
    font-weight: 700;
    line-height: 34px;
    color: #1b0115;
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 10px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
  }
  
  .allText {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 40px;
    width: 50%;
    margin: 0 0 0 0;
  }
  
  .text-blk.subHeadingText {
    color: rgb(0, 0, 0);
    font-size: 40px;
    line-height: 34px;
    font-weight: 700;
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 10px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
  }
  
  .text-blk.description {
    font-size: 20px;
    line-height: 34px;
    color: rgb(61, 61, 61);
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 50px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 10px;
  }
  
  .explore {
    font-size: 20px;
    color: rgb(255, 255, 255);
    background-color: #141414 ;
    padding: 10px 20px; /* Adjust padding as needed */
    border-radius: 10px; /* Adjust the border-radius for a more rounded look */
    border: none; /* Remove default button border */
    cursor: pointer; /* Add a pointer cursor on hover for better UX */
  }
  
  .explore:hover {
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: rgb(209, 209, 209);
    color: rgb(0, 0, 0);
  }
  
  .responsive-container-block.Container {
    margin-top: 80px;
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    max-width: 1320px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
  }
  
  .responsive-container-block.Container.bottomContainer {
    margin-top: 100px;
    margin-right: 0px;
    margin-bottom: 50px;
    margin-left: 0px;
    flex-direction: row-reverse;
    margin: 100px auto 50px auto;
  }
  
  .allText.aboveText {
    margin: 0 0 0 40px;
  }
  
  .allText.bottomText {
    margin: 0 40px 0 0;
  }
  
  @media (max-width: 1024px) {
    .responsive-container-block.Container {
      max-width: 850px;
    }
  
    .mainImg {
      width: 55%;
      height: auto;
    }
  
    .text-blk.description {
      font-size: 20px;
    }
  
    .allText {
      width: 40%;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 20px;
    }
  
    .responsive-container-block.bigContainer {
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
    }
  
  
  
    .responsive-container-block.Container.bottomContainer {
      margin: 80px auto 50px auto;
    }
  
    .responsive-container-block.Container {
      max-width: 830px;
    }
  
    .allText.aboveText {
      margin: 30px 0 0 40px;
    }
  
    .allText.bottomText {
      margin: 30px 40px 0 0;
    }
  }
  
  @media (max-width: 768px) {
    .mainImg {
      width: 90%;
    }
  
    .allText {
      width: 100%;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
    }
  
    .responsive-container-block.Container {
      flex-direction: column;
      height: auto;
    }
  
    .text-blk.headingText {
      text-align: center;
    }
  
    .text-blk.subHeadingText {
      text-align: center;
      font-size: 40px;
    }
  
    .text-blk.description {
      text-align: center;
      font-size: 25px;
    }
  
    .allText {
      margin-top: 40px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .allText.aboveText {
      margin: 40px 0 0 0;
    }
  
    .responsive-container-block.Container {
      margin: 80px auto 50px auto;
    }
  
    .responsive-container-block.Container.bottomContainer {
      margin: 50px auto 50px auto;
    }
  
    .allText.bottomText {
      margin: 40px 0 0 0;
    }
  }
  
  @media (max-width: 500px) {
    .responsive-container-block.Container {
      padding-top: 10px;
      padding-right: 0px;
      padding-bottom: 10px;
      padding-left: 0px;
      width: 100%;
      max-width: 100%;
    }
  
    .mainImg {
      width: 100%;
    }
  
    .responsive-container-block.bigContainer {
      padding-top: 10px;
      padding-right: 25px;
      padding-bottom: 10px;
      padding-left: 25px;
    }
  
    .text-blk.subHeadingText {
      
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
    }
  
    .text-blk.description {
      font-size: 25px;
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
    }
  
    .allText {
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      width: 100%;
    }
  }

  .allText h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 300;
    color: #272424;
  }
  .allText span{
    font-weight: bold;
    color :#111
  }