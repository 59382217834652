
.solutions .icon-box {
  margin-bottom: 20px;
  padding: 30px;
  border-radius: 6px;
  background: #252525;
  transition: 0.3s;
  position: relative;
}

.solutions .icon-box:hover {
  background: #2b2b2b;
}


.solutions .icon-box .so_icon {
  float: left;
  color: #f7f57f;
  font-size: 40px;
  line-height: 0;
}

.solutions .icon-box h4 {
  margin-left: 70px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 25px;
}


.solutions .icon-box h4 a {
  color: #fff;
  transition: 0.3s;
}

.solutions .icon-box h4 a:hover {
  text-decoration: underline;
}

.solutions .icon-box .icon-box:hover h4 a {
  color: #f7f57f;
}

.solutions .icon-box p {
  margin-left: 70px;
  line-height: 24px;
  font-size: 14px;
}

/****************/
section {
    padding: 60px 0;
    overflow: hidden;
    position: relative;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
    position: relative;
  }
  
  .section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
  }
  
  .section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #f7f57f;
    bottom: 0;
    left: calc(50% - 25px);
  }
  
  .section-title p {
    margin-bottom: 0;
  }
  
  .section-bg {
    padding: 120px 0;
    color: #fff;
  }
  
  .section-bg:before {
    content: "";
    background: #1b1b1b;
    position: absolute;
    bottom: 60px;
    top: 60px;
    left: 0;
    right: 0;
    transform: skewY(-3deg);
  }
  