/* Paste your CSS content here */
#aboutpage {
  width: 100%;
  background-image:  url('../../assets/v882-kul-66.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;
  margin-top: 12px;
  padding: 60px 0;
  height: 50vh;
  
  
  
}
.containerrr_abouttt {
  
  margin: 100px;
  
  
  
}

#aboutpage h1 {
  margin: 0 0 20px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #fcfbbc;
  
}

#aboutpage h2 {
  color:  #ffffff;
  margin-bottom: 30px;
  font-size: 24px;
}

#aboutpage .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 28px;
  border-radius: 3px;
  transition: 0.5s;
  color: #000000;
  background: #f1ef71;
  text-decoration: none;
}

#aboutpage .btn-get-started:hover {
  background: #000000;
  color:bisque;
}

#aboutpage .aboutttpage-img {
  text-align: center;
}

#aboutpage .aboutttpage-img img {
  width: 70%;
}

@media (min-width: 1024px) {
  #aboutpage
{
      background-attachment: fixed;
  }
}

@media (max-width: 992px) {
  #aboutpage
h1 {
      font-size: 32px;
      line-height: 40px;
  }

  #aboutpage
h2 {
      font-size: 20px;
  }
.img-fluid{
  width: 80%;
}
}


/***************************************/

.features .content+.content {
  margin-top: 100px;
}

.features .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.features .content ul {
  list-style: none;
  padding: 0;
}

.features .content ul li {
  padding-bottom: 10px;
}

.features .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #3b4ef8;
}

.features .content p:last-child {
  margin-bottom: 0;
  font-size: 20px;
}

/******************************************/

.abouttt .container {
  position: relative;
  z-index: 10;
}

.abouttt .content {
  padding: 30px 30px 30px 0;
}

.abouttt .content h3 {
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 30px;
}

.abouttt .content p {
  margin-bottom: 30px;
}

.abouttt .content .abouttt-btn {
  padding: 8px 30px 9px 30px;
  color: #fff;
  border-radius: 50px;
  transition: 0.3s;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  border: 2px solid #f7f57f;
}

.abouttt .content .abouttt-btn .ao_icon {
  font-size: 16px;
  padding-left: 5px;
}

.abouttt .content .abouttt-btn:hover {
  background: #e35052;
  background: #f7f57f;
}

.abouttt .icon-boxes .icon-box {
  margin-top: 30px;
}

.abouttt .icon-boxes .icon-box .ao_icon {
  font-size: 40px;
  color: #f7f57f;
  margin-bottom: 10px;
}

.abouttt .icon-boxes .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 10px 0;
}

.abouttt .icon-boxes .icon-box p {
  font-size: 15px;
  color: #848484;
}

@media (max-width: 1200px) {
  .abouttt .content {
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .abouttt {
    text-align: center;
  }
}

