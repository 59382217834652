
.call-to-action {
  /* background: linear-gradient(rgba(27, 47, 69, 0.8), rgba(27, 47, 69, 0.8)), url('../../assets/FreeConst2.jpg') center center; */
  background:  url('../../assets/bakgold3.jpg') center center;
  background-size: cover;
  padding: 100px 0;
  opacity: 1;
}

@media (min-width: 1365px) {
  .call-to-action {
    background-attachment: fixed;
  }
}

.call-to-action h3 {
  color: #fff;
  font-size: 28px;
  margin-bottom: 25px;
  font-weight: 700;
}
.call-to-action a {
    text-decoration: none;
   
  }
.call-to-action p {
  color: #fff;
  margin-bottom: 25px;
}

.call-to-action .cta-btn {
  font-family: var(--font-default);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  color: #000000;
  background: #f7f45e;
}

.call-to-action .cta-btn:hover {
  background: var(--color-primary);
  color: #fff;
}