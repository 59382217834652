.homepage{
    background: url('../../assets/about-bg.jpg') center top no-repeat fixed;
    background-size: cover;
    padding: 60px 0 40px 0;
    position: relative;
  }
  .homepage::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.92);
    z-index: 9;
  }
  .homepage_container{
    position: relative;
  z-index: 10;
  }